import video01 from "@/images/about/3.jpg";
import video02 from "@/images/home_1/3.jpg";

export const VideoOneData = {
  sectionContent: {
    title: "Logistics Management Software Solution",
    subTitle: "We are the partner you need",
    text:
      "LoadBud is trusted by various customers. You can join them today and experience the benefits of LoadBud for yourself. Sign up for a free trial or request a demo now!",
  },
  video: {
    image: video01,
    ID: "CM-Ka7BvOpc",
    title: "Watch Video",
  },
};

export const VideoTwoData = {
  sectionContent: {
    title: "LoadBud Service",
    subTitle: "how do we works",
    text:
      "We are committed to providing our customers with exceptional service.",
  },
  video: {
    title: "Watch Video",
    ID: "CM-Ka7BvOpc",
    image: video02,
  },
};


