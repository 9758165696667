import aboutOne01 from "@/images/about/1.jpg";
import aboutOne02 from "@/images/about/2.jpg";

import aboutTwo01 from "@/images/home_1/2.jpg";
import aboutTwo02 from "@/images/home_1/1.jpg";

export const AboutOneData = {
  sectionContent: {
    title: "Your PARTNER IN LOGISTICS",
    subTitle: "get to know us",
    text:
      `LoadBud Logistics Software Solution provides innovative and efficient solutions for the transportation and logistics industries. Our product assists our clients in optimizing their operations, lowering their expenses, and enhancing customer satisfaction. Our mission is to equip our customers with the most effective tools and technologies to manage their logistics challenges and attain their business objectives.

      Our team of skilled and enthusiastic professionals is committed to delivering high-quality solutions and superior customer service. We continually innovate and enhance our products to meet the evolving demands of the logistics industry. As we believe that our solutions can positively impact the world, we are also committed to social responsibility and environmental sustainability.
 
      Look no further than LoadBud Logistics Software Solution if you are searching for a dependable and trustworthy partner for your logistics needs. Contact us immediately to learn how we can assist you in expanding your business.
      `,
  },
  gallery: [aboutOne01, aboutOne02],
    button: {
    label: "Learn More",
    url: "#",
  },
  counter: {
    title: "Company Started",
    number: 2021,
  },
};

export const AboutTwoData = {
  sectionContent: {
    title: "The ultimate logistics software solution",
    subTitle: "Your Partner in Logistics",
    text:
      "LoadBud is a cloud-based software platform that helps you manage your logistics operations with ease and efficiency. Whether you need to manage your fleet, optimize your routes, track your shipments, or streamline your operations, LoadBud has you covered.",
  },
  button: {
    label: "Learn More",
    url: "/about",
  },
  gallery: [aboutTwo01, aboutTwo02],
};