export const FunfactData = [
    {
      title: "Projects Completed",
      countNumber: 705,
    },
    {
      title: "Active Clients",
      countNumber: 480,
    },
    {
      title: "Cups of Coffee",
      countNumber: 626,
    },
    {
      title: "Happy Clients",
      countNumber: 774,
    },
  ];
  