export const ContactInfosBlock = {
    subTitle: "our offices",
    title: "offices near you",
    description:
        "We are committed to providing our customers with exceptional service while \n offering our employees the best training.",
};

export const ContactInfosList = [
    {
        title: "austin",
        infos: [
            {
                name: "22 Texas West Hills",
            },
            {
                name: "info@loadbud.com",
            },
            {
                name: "666 888 000",
            },
        ],
    },
    {
        title: "boston",
        infos: [
            {
                name: "5 Federal Street Boston",
            },
            {
                name: "info@loadbud.com",
            },
            {
                name: "666 888 000",
            },
        ],
    },
    {
        title: "new york",
        infos: [
            {
                name: "8th Broklyn New York",
            },
            {
                name: "info@loadbud.com",
            },
            {
                name: "666 888 000",
            },
        ],
    },
    {
        title: "baltimore",
        infos: [
            {
                name: "3 Lombabr 50 baltimore",
            },
            {
                name: "info@loadbud.com",
            },
            {
                name: "666 888 000",
            },
        ],
    },
];

export const ContactFormTitle = {
    subTitle: "Contact with us",
    title: "write us a message",
    description:
        `If you have any questions, comments, or feedback, please fill out the contact form below and we will get back to you as soon as possible. 
        We value your input and appreciate your interest in our company.`,
};