import testimonial01 from "@/images/home_1/t1.jpg";
import testimonial02 from "@/images/home_1/t2.jpg";
import testimonial03 from "@/images/home_1/t3.jpg";

export const TestimonialsOneData = {
  sectionContent: {
    subTitle: "our testimonials",
    title: "happy customers",
  },
  posts: [
    {
      name: "Cecilia Colon",
      designation: "Director",
      image: testimonial01,
      content:
        "I have been using LoadBud for several months now, and it has completely transformed the way I manage my logistics operations. It has helped me streamline my processes and save a significant amount of time and money.",
      date: "25 nov, 2018",
    },
    {
      name: "Chase Hanson",
      designation: "CO Founder",
      image: testimonial02,
      content:
        "LoadBud has made it so much easier for me to track shipments and communicate with my team. The software is user-friendly and intuitive, and the customer support team is always available to answer my questions.",
      date: "25 nov, 2018",
    },
    {
      name: "Mittie Flores",
      designation: "Manager",
      image: testimonial03,
      content:
        "I was hesitant to switch to a new logistics software, but I am so glad I did. LoadBud has exceeded my expectations in every way. It has helped me improve efficiency, reduce errors, and provide better service to my customers.",
      date: "25 nov, 2018",
    },
    {
      name: "Carrie Sims",
      designation: "Director",
      image: testimonial01,
      content:
        "LoadBud has helped me stay on top of my inventory and make better decisions about ordering and shipping. The software is easy to use and has a wide range of features that have helped me streamline my operations.",
      date: "25 nov, 2018",
    },
    {
      name: "Anne Stone",
      designation: "CO Founder",
      image: testimonial02,
      content:
        "LoadBud has been a game-changer for my business. It has helped me save time and money, and has improved my customer satisfaction. I would highly recommend it to anyone looking to optimize their logistics operations.",
      date: "25 nov, 2018",
    },
    {
      name: "Hunter Brewer",
      designation: "Manager",
      image: testimonial03,
      content:
        "I have been using LoadBud for several months now, and it has been a great investment. The software has helped me manage my logistics operations more efficiently, and has provided me with valuable insights into my business.",
      date: "25 nov, 2018",
    },
    {
      name: "Carrie Sims",
      designation: "Director",
      image: testimonial01,
      content:
        "LoadBud has helped me improve my supply chain management and reduce costs. The software is easy to use and has a wide range of features that have helped me streamline my operations.",
      date: "25 nov, 2018",
    },
    {
      name: "Anne Stone",
      designation: "CO Founder",
      image: testimonial02,
      content:
        "LoadBud has helped me automate many of my logistics processes, which has saved me a lot of time and effort. The software is reliable and efficient, and the customer support team is always available to help.",
      date: "25 nov, 2018",
    },
    {
      name: "Hunter Brewer",
      designation: "Manager",
      image: testimonial03,
      content:
        "LoadBud has helped me improve my logistics operations in so many ways. It has helped me reduce costs, improve efficiency, and provide better service to my customers. I would highly recommend it to anyone in the logistics industry.",
      date: "25 nov, 2018",
    },
  ],
};