import featureTab01 from "@/images/home_1/c1.jpg";
import featureTab02 from "@/images/home_1/c2.jpg";
import featureTab03 from "@/images/home_1/c3.jpg";

import featureTwo01 from "@/images/home_1/5.jpg";

export const FeatureTabData = {
  sectionContent: {
    title: "why LoadBud?",
    subTitle: "our benefits",
    text:
      "With LoadBud, you can simplify your processes, maximize your assets, and increase your earnings.",
  },
  posts: [
    {
      title: "Simplify your workflow",
      content:
        "You can access all of your transportation data and documents in one location using LoadBud, regardless of your device. You can quickly and easily create, track, and manage your shipments, invoices, payments, and reports with a few mouse clicks. ",
      image: featureTab01,
      lists: [
        {
          item: "Automates your booking, invoicing, and tracking processes",
        },
        {
          item: "Reduces your paperwork and errors",
        },
        {
          item: "Streamlines your communication and collaboration with team members",
        },
        {
          item: "Saves you time and money",
        },
      ],
    },
    {
      title: "Enhance your visibility",
      content: "LoadBud provides you with real-time tracking and updates on your shipments, from pickup to delivery. ",
      image: featureTab02,
      lists: [
        {
          item: "Access a dashboard that shows you the status and performance of your shipments at a glance.",
        },
        {
          item: "It enables you to monitor and optimize your performance and profitability.",
        },
        {
          item: "Allows you to generate and share reports and analytics that help you optimize your logistics operations.",
        },
        {
          item: "Alerts you of any issues or delays and helps you resolve them quickly",
        },
        // {
        //   item: "Payroll integration",
        // },
        // {
        //   item: "Built-in backup camera to verify failed punches",
        // },
      ],
    },
    {
      title: "Professional Service",
      content:
        "Our knowledgeable and helpful staff will gladly assist you with any issues. We value your feedback and strive to offer you the highest quality service possible.",
      image: featureTab03,
      lists: [
        {
          item: "LoadBud offers more than just software. We work with you on logistics. ",
        },
        {
          item: "Support is available via phone, email, live chat, and a ticket system.",
        },
        {
          item: "Ensures compliance with industry standards and regulations",
        },
        {
          item: "Guarantees quality and satisfaction",
        },
        // {
        //   item: "Payroll integration",
        // },
        // {
        //   item: "Built-in backup camera to verify failed punches",
        // },
      ],
    },
  ],
};

export const FeatureTwoData = {
  sectionContent: {
    title: "IMPROVE PRODUCTIVITY",
    subTitle: "our core features",
    text:
      "LoadBud offers a comprehensive suite of features and functionalities that cater to your specific needs and preferences.",
  },
  posts: [
    {
      title: "Manage All the Loads",
      text:
        "Take control of all the truckloads, make it simple to track, assign, and optimize the loads for each truck in your fleet, and manage the entire fleet.",
    },
    {
      title: "Track Your Deliveries",
      text:
        "Monitor the delivery status and performance of your drivers that helps you save time, money, and resources, while ensuring customer satisfaction.",
    },
    {
      title: "Connect With Team",
      text:
        "Communicate with team members in real time to monitor the status of deliveries, coordinate schedules, and provide feedback or support.",
    },
  ],
  image: {
    text: "Simplify processes \n for everyone.",
    path: featureTwo01,
  },
};