import logoLight from "@/images/logo.png";
import logoDark from "@/images/logo2.png";

export const LogoImage = {
  light: logoLight,
  dark: logoDark,
};
export const SiteInfo = {
  title: "LoadBud",
  description: "LoadBud Logistics Software Solution provides innovative and efficient solutions for the transportation and logistics industries. "
}

export const NavLinks = [
  {
    name: "Home",
    url: "/",
    // subItems: [
    //   {
    //     name: "Home 01",
    //     url: "/",
    //   },
    //   {
    //     name: "Home 02",
    //     url: "/index-2",
    //   },
    //   {
    //     name: "Home 03",
    //     url: "/index-3",
    //   },
    // ],
  },
  {
    name: "Features",
    url: "/features",
    // subItems: [
    //   {
    //     name: "Services",
    //     url: "/service",
    //   },
    //   {
    //     name: "Service Details",
    //     url: "/service-details",
    //   },
    // ],
  },
  {
    name: "About",
    url: "/about",
  },
  // {
  //   name: "Portfolio",
  //   url: "/portfolio",
  //   subItems: [
  //     {
  //       name: "Portfolio 01",
  //       url: "/portfolio",
  //     },
  //     {
  //       name: "Portfolio Details",
  //       url: "/portfolio-details",
  //     },
  //   ],
  // },
  {
    name: "Blog",
    url: "/blog",
    // subItems: [
    //   {
    //     name: "Blog 01",
    //     url: "/blog",
    //   },
    //   {
    //     name: "Blog 02",
    //     url: "/blog-2",
    //   },
    //   {
    //     name: "Blog 03",
    //     url: "/blog-3",
    //   },
    //   {
    //     name: "Blog Details",
    //     url: "/blog-single",
    //   },
    // ],
  },
  {
    name: "Contact",
    url: "/contact",
  },
];