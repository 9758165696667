import React, { Fragment, useContext, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { SearchContext } from "@/context/search-context";
import { MenuContext } from "@/context/menu-context";
import SearchPopup from "@/components/search-popup";
import PopupMenu from "@/components/popup-menu";
import { Link as ScrollLink } from "react-scroll";
import { SiteInfo } from "@/data";

import "typeface-oswald";
import "bootstrap/dist/css/bootstrap.min.css";
import "@/css/animate.css";
import "@/css/font-awesome.min.css";
import "@/css/icons.css";
import "@/css/preset.css";
import "@/css/theme.css";
import "@/css/responsive.css";

const Layout = ({ PageTitle, PageDescription, PageImage, children }) => {
  const { searchStatus } = useContext(SearchContext);
  const { menuStatus } = useContext(MenuContext);
  const [scrollTop, setScrollTop] = useState(false);
  const {title, description } = SiteInfo;
  const isBrowser = typeof window !== "undefined";
  let currentPath = "";

  const handleScrollTop = () => {
    if (window.scrollY > 70) {
      setScrollTop(true);
    } else if (window.scrollY < 70) {
      setScrollTop(false);
    }
  };

  useEffect(() => {
    if (isBrowser) {
      currentPath = window.location.href;
    }
    window.addEventListener("scroll", handleScrollTop);
    return () => {
      window.removeEventListener("scroll", handleScrollTop);
    };
  }, [scrollTop,isBrowser]);

  return (
    <Fragment>
      <Helmet>
        <title>
          {PageTitle} - {title} Logistics Management Solution
        </title>
        <meta name="description" content={PageDescription || description}/>
        <meta property="og:site_name" content={title}/>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content={PageTitle}/>
        <meta property="og:description" content={PageDescription}/>
        <meta property="og:url" content={currentPath}/>
        <meta property="og:image" content={PageImage}/>
        {/* <meta property="article:published_time" content="2023-07-24T20:03:22.000Z"/>
        <meta property="article:modified_time" content="2023-07-24T20:12:41.000Z"/>
        <meta property="article:tag" content="Apps"/>
        <meta property="article:publisher" content="https://www.facebook.com/hottechcom"/> */}
        {/* <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content="As of now, Twitter&#x27;s official logo is the &#x27;X&#x27;"/>
        <meta name="twitter:description" content="Twitter, one of the world&#x27;s most well-known social media sites, has replaced the blue bird logo with an &#x27;X&#x27; as its official logo. This decision was made following an announcement by Elon Musk over the weekend. The website has already implemented the changes. According to the business, the new logo"/>
        <meta name="twitter:url" content="https://hot-tech.com/as-of-now-twitters-official-logo-is-the-x/"/>
        <meta name="twitter:image" content="https://hot-tech.com/content/images/2023/07/x-logo.jpeg"/>
        <meta name="twitter:label1" content="Written by"/>
        <meta name="twitter:data1" content="Author"/>
        <meta name="twitter:label2" content="Filed under"/>
        <meta name="twitter:data2" content="Apps"/>
        <meta name="twitter:site" content="@hottech"/> */}
        <meta property="og:image:width" content="1600"/>
        <meta property="og:image:height" content="900"></meta>
      </Helmet>
      <div id="wrapper">{children}</div>
      {true === searchStatus ? <SearchPopup /> : null}
      {true === menuStatus ? <PopupMenu /> : null}

      {scrollTop === true ? (
        <ScrollLink
          to="wrapper"
          smooth={true}
          duration={500}
          id="backToTop"
          className="scroll-to-top showit"
        >
          <i className="fa fa-angle-double-up"></i>
        </ScrollLink>
      ) : null}
    </Fragment>
  );
};

export default Layout;
