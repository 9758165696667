
import team01 from "@/images/team/1.jpg";
import team02 from "@/images/team/2.jpg";
import team03 from "@/images/team/3.jpg";
import team04 from "@/images/team/4.jpg";
import team05 from "@/images/team/5.jpg";

export const TeamOneData = {
  sectionContent: {
    title: "expert people",
    subTitle: "meet the team",
    text:
      "We are committed to providing our customers with exceptional service \n while offering our employees the best training.",
  },
  posts: [
    {
      image: team01,
      name: "Herman Fisher",
      designation: "General Manager",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team02,
      name: "Charlie Kennedy",
      designation: "Brand Designer",
      url: "",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team03,
      name: "Helena Adkins",
      designation: "Seinor Designer",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team04,
      name: "Virginia Farmer",
      designation: "Marketing Manager",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team05,
      name: "Philip Hansen",
      designation: "Co Founder",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
  ],
};