// --- SLIDER 1 ---
import sliderOne01 from "@/images/slider/slider1.jpg";
import sliderOne02 from "@/images/slider/slider2.jpg";

// --- SLIDER 2 ---
import sliderTwo01 from "@/images/slider/2_1.jpg";
import sliderTwo02 from "@/images/slider/2_2.jpg";
import sliderTwo03 from "@/images/slider/2_3.jpg";

// --- SLIDER 3 ---
import sliderThree01 from "@/images/slider/3_1.jpg";
import sliderThree02 from "@/images/slider/3_2.jpg";


/*
* ****  HOME PAGE 1 SLIDER ****
*/

export const SliderOneData = [
    {
        image: sliderOne01,
        subTitle: "WELCOME TO LOADBUD",
        title: "Logistics Management Solution",
        button: {
            label: "DISCOVER MORE",
            url: "/about",
        },
    },
    {
        image: sliderOne02,
        subTitle: "YOUR TRANSPORTATION PARTNER",
        title: "Loadbud makes \nprocesses simple",
        button: {
            label: "DISCOVER MORE",
            url: "/about",
        },
    },
];

/*
* ****  HOME PAGE 2 SLIDER ****
*/

export const SliderTwoData = [
    {
        image: sliderOne01,
        subTitle: "WELCOME TO LOADBUD",
        title: "Logistics Management Solution",
        button: {
            label: "DISCOVER MORE",
            url: "/about",
        },
    },
    {
        image: sliderOne02,
        subTitle: "YOUR TRANSPORTATION PARTNER",
        title: "Loadbud made \nlogistics easy.",
        button: {
            label: "DISCOVER MORE",
            url: "/about",
        },
    },
];

/*
* ****  HOME PAGE 3 SLIDER ****
*/

export const SliderThreeData = [
    {
        image: sliderThree01,
        subTitle: "WELCOME TO BUS AGENCY",
        title: "SMART WEB\n DESIGN AGENCY.",
        button: {
            label: "DISCOVER MORE",
            url: "/about",
        },
    },
    {
        image: sliderThree02,
        subTitle: "WELCOME TO BUT AGENCY",
        title: "SMART WEB\n DESIGN AGENCY.",
        button: {
            label: "DISCOVER MORE",
            url: "/about",
        },
    },
];