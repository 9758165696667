
import serviceOne01 from "@/images/home_1/6.jpg";
import serviceOne02 from "@/images/home_1/7.jpg";
import serviceOne03 from "@/images/home_1/8.jpg";

export const ServicePostData = [
    {
      title: "MANAGE ALL THE LOADS",
      text:
        "Take control of all the truckloads, make it simple to track, assign, and optimize the loads for each truck in your fleet, and manage the entire fleet.",
      iconName: "mei-settings",
      url: "/service-details",
    },
    {
      title: "TRACK YOUR DELIVERIES",
      text:
        "Monitor delivery status and performance of drivers that helps you save time, money & resources.",
      iconName: "mei-transfer",
      url: "/service-details",
    },
    {
      title: "CONNECT WITH TEAM",
      text:
        "Communicate with team members to monitor the status of deliveries, coordinate schedules, and more.",
      iconName: "mei-pie-chart",
      url: "/service-details",
    },
  ];
  
  export const ServiceHomeTwoData = {
    subTitle: "Welcome to LoadBud",
    title: "The ultimate logistics management\n software for your business",
    text:
      "Streamline your operations, optimize your resources, and boost your profits with LoadBud.",
  };
  
  export const ServiceHomeThreeData = {
    subTitle: "Services we are offering",
    title: "Our Services",
    text:
      "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
  };
  
  export const ServicePostTwoData = {
    sectionContent: {
      title: "The ultimate logistics management\n system for your business.",
      subTitle: "Services we are offering",
      text:
        "Streamline your operations, optimize your resources, and boost your profits with LoadBud.",
    },
    posts: [
      // {
      //   title: "Website Development",
      //   iconName: "mei-web-design",
      //   url: "/service-details",
      // },
      // {
      //   title: "Graphic Designing",
      //   iconName: "mei-computer-graphic",
      //   url: "/service-details",
      // },
      // {
      //   title: "Digital Marketing",
      //   iconName: "mei-development-1",
      //   url: "/service-details",
      // },
      // {
      //   title: "SEo & Content Writing",
      //   iconName: "mei-development",
      //   url: "/service-details",
      // },
      // {
      //   title: "App Development",
      //   iconName: "mei-app-development",
      //   url: "/service-details",
      // },
    ],
  };
  
  export const ServicePostThreeData = {
    sectionContent: {
      title: "Let’s create something",
      subTitle: "what we do",
      text:
        "We are committed to providing our customers with exceptional service \n while offering our employees the best training.",
    },
    posts: [
      {
        title: "web development",
        image: serviceOne01,
        url: "/service-details",
      },
      {
        title: "digital marketing",
        image: serviceOne02,
        url: "/service-details",
      },
      {
        title: "product branding",
        image: serviceOne03,
        url: "/service-details",
      },
    ],
  };